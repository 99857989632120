export const environment = {
  production: false,
  local: false,
  configUrl: './assets/configuration/config.json',
  table: {
    pageSize: 5
  },
  autoComplete: {
    maxResults: 10
  }
};
